import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, delay, mergeMap, tap } from 'rxjs/operators';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import {
  NavigateTo,
  NavigateToWithQueryParams,
} from 'src/app/core/actions/navigation.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { OrderMomentService } from 'src/app/core/api/order-moment/v1/order-moment.service';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountState } from 'src/app/core/states/account.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import {
  ProposedBuyOrder,
  ProposedBuyOrders,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../../../actions/purchase-v3.actions';
import { STRINGS } from '../../../model/purchase-v3.strings';
import {
  AddFilterParam,
  AddFilterParamNoReload,
  AddSearchParam,
  BuyNow,
  ColumnsSelected,
  HideColumn,
  IgnoreSupplier,
  InitializeProposedOrders,
  LoadProposedOrdersV2,
  LoseFocus,
  Order,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetPagination,
  ResetPaginationAndLoadData,
  SkipOrderMoment,
  Sort,
  ToggleFilter,
} from '../actions/proposed-orders.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultExtraFiltersV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import { LoadOrdersIssues } from 'src/app/features/dashboard/components/todo-list-v2/actions/todo-list-v2.actions';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface ProposedOrdersStateModel extends DatatableStateModel {
  proposedOrders: ProposedBuyOrder[];
  page: Pageable;
  loading: boolean;
  isPageOnFocus: boolean;
  extraFiltersGroups: DatatableColumnV2Groups<Filter>;
}

@State<ProposedOrdersStateModel>({
  name: 'proposedOrdersState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    sortBy: defaultSort,
    search: [],
    proposedOrders: [],
    page: { totalElements: 0 },
    loading: true,
    filterOpened: false,
    isPageOnFocus: false,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    extraFiltersGroups: defaultExtraFiltersV2,
  },
})
@Injectable()
export class ProposedOrdersState extends DatatableState {
  private readonly NOTIFICATIONS = STRINGS.notifications.purchase;

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ProposedOrdersState],
      (state: ProposedOrdersStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static columns(
    state: ProposedOrdersStateModel
  ): DatatableColumnV2Groups<DatatableColumnV2> {
    return state.columnsGroups;
  }

  constructor(
    private store: Store,
    private buyOrderService: BuyOrderV2Service,
    private suppliersV2Service: SuppliersV2Service,
    private orderMomentService: OrderMomentService,
    private accountSettingsService: AccountSettingsService
  ) {
    super();
  }

  @Action(InitializeProposedOrders, { cancelUncompleted: true })
  initializeProposedOrders(ctx: StateContext<ProposedOrdersStateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.plannedOrdersV2TableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<ProposedOrdersStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch([new LoadOrdersIssues(), new LoadProposedOrdersV2()]);
  }

  @Action(LoadProposedOrdersV2, { cancelUncompleted: true })
  loadOrders(ctx: StateContext<ProposedOrdersStateModel>) {
    ctx.setState(
      patch({
        loading: true,
        isPageOnFocus: true,
      })
    );

    return this._fetchProposedOrders(ctx).pipe(
      tap(() => ctx.setState(patch({ loading: false }))),
      delay(300000),
      concatMap(() => {
        if (ctx.getState().isPageOnFocus) {
          return ctx.dispatch(LoadProposedOrdersV2);
        }

        return of(null);
      }),
      catchError(() => {
        ctx.patchState({
          loading: false,
          page: { totalElements: 0 },
          proposedOrders: [],
        });

        return of(false);
      })
    );
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ProposedOrdersStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ProposedOrdersStateModel>) {
    return ctx.dispatch(new LoadProposedOrdersV2());
  }

  @Action(LoseFocus, { cancelUncompleted: true })
  loseFocus(ctx: StateContext<ProposedOrdersStateModel>) {
    ctx.patchState({
      isPageOnFocus: false,
    });
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ProposedOrdersStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadProposedOrdersV2())),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadProposedOrdersV2());
  }

  @Action(Order, { cancelUncompleted: true })
  order(ctx: StateContext<ProposedOrdersState>, payload: Order) {
    window.rudderanalytics.track('Open Order Moment', {
      supplier_id: payload.buyorder.order.supplier.id,
      supplier_name: payload.buyorder.order.supplier.name,
      order_moment_uuid: payload.buyorder.order.orderMoment.uuid,
      supplier_uuid: payload.buyorder.order.supplier.uuid,
      total_order_value: payload.buyorder.order.totalValue,
    });

    return ctx.dispatch(
      new NavigateToWithQueryParams(
        [
          'purchase',
          'editor',
          'proposed',
          payload.buyorder.order.supplier.uuid,
          payload.buyorder.order.orderMoment.type,
        ],
        {
          orderMomentId: payload.buyorder.order.orderMoment.id,
          orderMomentUUID: payload.buyorder.order.orderMoment.uuid,
          orderMomentType: payload.buyorder.order.orderMoment.type,
          supplierId: payload.buyorder.order.supplier.id,
          planningTrigger: payload.buyorder.order.planningTrigger,
          future: payload.buyorder.orderFuture,
        }
      )
    );
  }

  @Action(BuyNow, { cancelUncompleted: true })
  buyNow(ctx: StateContext<ProposedOrdersState>, payload: BuyNow) {
    window.rudderanalytics.track('Buy Now Order', {
      supplier_id: payload.buyorder.order.supplier.id,
      supplier_name: payload.buyorder.order.supplier.name,
      supplier_uuid: payload.buyorder.order.supplier.uuid,
      total_order_value: payload.buyorder.order.totalValue,
    });

    return ctx.dispatch(
      new NavigateToWithQueryParams(
        [
          'purchase',
          'editor',
          'proposed',
          payload.buyorder.order.supplier.uuid,
          'REGULAR',
        ],
        {
          supplierId: payload.buyorder.order.supplier.id,
          planningTrigger: payload.buyorder.order.planningTrigger,
        }
      )
    );
  }

  @Action(SkipOrderMoment, { cancelUncompleted: true })
  skipOrderMoment(
    ctx: StateContext<ProposedOrdersState>,
    payload: SkipOrderMoment
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected).uuid;

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._patchOrderMoment(ctx, {
          webshopUUID,
          orderMomentUUID: payload.orderMomentUUID,
          skipped: payload.skipped,
        })
      ),
      delay(1500),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      tap(() => {
        payload.dialogRef.close();

        if (payload.reroute) {
          ctx.dispatch(new NavigateTo(['purchase', 'proposed']));
        }
      }),
      concatMap(() => ctx.dispatch(new LoadProposedOrdersV2())),
      catchError(() => {
        payload.dialogRef.close();

        return of(false);
      })
    );
  }

  @Action(IgnoreSupplier, { cancelUncompleted: true })
  ignoreSupplier(
    ctx: StateContext<ProposedOrdersState>,
    payload: IgnoreSupplier
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected).uuid;

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._updateSupplier(ctx, {
          webshopUUID,
          supplierUUID: payload.supplierUUID,
          ignored: payload.ignored,
        })
      ),
      delay(1500),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      tap(() => {
        payload.dialogRef.close();

        if (payload.reroute) {
          ctx.dispatch(new NavigateTo(['purchase', 'proposed']));
        }
      }),
      concatMap(() => ctx.dispatch(new LoadProposedOrdersV2())),
      catchError(() => {
        payload.dialogRef.close();

        return of(false);
      })
    );
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: AddFilterParam | AddSharedFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: AddSearchParam | AddSharedSearchParam
  ) {
    ctx.setState(
      patch<ProposedOrdersStateModel>({
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: RemoveSearchParam | RemoveSharedSearchParam
  ) {
    ctx.setState(
      patch<ProposedOrdersStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ProposedOrdersStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<ProposedOrdersStateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<ProposedOrdersStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<ProposedOrdersStateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ProposedOrdersStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(AddFilterParamNoReload, { cancelUncompleted: true })
  addFilterParamNoReload(
    ctx: StateContext<ProposedOrdersStateModel>,
    payload: AddFilterParamNoReload
  ) {
    payload.param.forEach(element => {
      const groupKey = filtersGroupsMap.get(element.columnKey);

      if (!groupKey) return;

      this.addGroupParam(ctx, groupKey, element.columnKey, {
        operator: element.optionSelected as FilterTypesOptionsV2,
        subOperator: element.subOperator,
        value: element.values,
      });
    });

    if (payload.reload) {
      return ctx.dispatch(new ResetPaginationAndLoadData());
    }
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ProposedOrdersStateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadProposedOrdersV2()]);
  }

  override addGroupParam(
    ctx: StateContext<ProposedOrdersStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ProposedOrdersStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ProposedOrdersStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadProposedOrdersV2());
  }

  private _removeAllFilters(ctx: StateContext<ProposedOrdersStateModel>) {
    ctx.patchState({
      search: [],
      sortBy: defaultSort,
      filtersGroups: defaultFiltersV2,
    });
  }

  private _fetchProposedOrders(
    ctx: StateContext<ProposedOrdersStateModel>
  ): Observable<ProposedBuyOrders> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildProposedOrdersRequestInfo(ctx);

    return this.buyOrderService
      .findAllProposedBuyOrders(webshopUuid, requestInfo)
      .pipe(
        tap((orders: ProposedBuyOrders) => {
          ctx.patchState({
            proposedOrders: orders.data,
            page: { totalElements: orders.metadata.page.totalElements },
          });
        }),
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildProposedOrdersRequestInfo(
    ctx: StateContext<ProposedOrdersStateModel>
  ): ServiceRequestInfoV3 {
    const state = ctx.getState();

    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const extraFiltersWithoutGroups: Filter[] = Object.values(
      state.extraFiltersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: {
          ...this.buildFiltersV2(filtersWithoutGroups),
          ...this.buildFiltersV2(extraFiltersWithoutGroups, false),
        },
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _patchOrderMoment(
    ctx: StateContext<ProposedOrdersState>,
    payload: { webshopUUID: string; orderMomentUUID: string; skipped: boolean }
  ): Observable<any> {
    return this.orderMomentService
      .patchOrderMoments(
        payload.webshopUUID,
        payload.orderMomentUUID,
        payload.skipped
      )
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _updateSupplier(
    ctx: StateContext<ProposedOrdersState>,
    payload: {
      webshopUUID: string;
      supplierUUID: string;
      ignored: boolean;
    }
  ): Observable<any> {
    return this.suppliersV2Service
      .updateSupplierGRPC(payload.webshopUUID, payload.supplierUUID, {
        ignored: payload.ignored,
      })
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ProposedOrdersStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      plannedOrdersV2TablePageSize: newPageSize,
    };
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ProposedOrdersStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      plannedOrdersV2TableExcludedColumns: excludedColumns,
      overridePlannedOrdersV2TableExcludedColumns: true,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<ProposedOrdersStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
